import { ChainId, Currency, CurrencyAmount, Price, Token, TradeType } from '@uniswap/sdk-core'
import { USDC_LZ_EON, USDC_LZ_FANTOM, USDT_BITTORRENT } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import { useUSDAPIQuote } from 'components/Quoter/useUSDAPIQuote'
import { USE_CLIENT_QUOTING } from 'constants/misc'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useRef } from 'react'
import { INTERNAL_ROUTER_PREFERENCE_PRICE } from 'state/routing/types'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'

import {
  BRIDGED_USDC_ARBITRUM,
  CUSD_CELO,
  DAI_OPTIMISM,
  USDC_AVALANCHE,
  USDC_BERATESTNET,
  USDC_MAINNET,
  USDC_POLYGON,
  USDC_SONIC,
  USDC_SONICTESTNET,
  USDT_BSC,
} from '../constants/tokens'

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
const STABLECOIN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token> } = {
  [ChainId.MAINNET]: CurrencyAmount.fromRawAmount(USDC_MAINNET, 100_000e6),
  [ChainId.ARBITRUM_ONE]: CurrencyAmount.fromRawAmount(BRIDGED_USDC_ARBITRUM, 10_000e6),
  [ChainId.OPTIMISM]: CurrencyAmount.fromRawAmount(DAI_OPTIMISM, 10_000e18),
  [ChainId.POLYGON]: CurrencyAmount.fromRawAmount(USDC_POLYGON, 10_000e6),
  [ChainId.CELO]: CurrencyAmount.fromRawAmount(CUSD_CELO, 10_000e18),
  [ChainId.BNB]: CurrencyAmount.fromRawAmount(USDT_BSC, 100e18),
  [ChainId.AVALANCHE]: CurrencyAmount.fromRawAmount(USDC_AVALANCHE, 10_000e6),
  [ChainId.FANTOM]: CurrencyAmount.fromRawAmount(USDC_LZ_FANTOM, 1_000e6),
  [ChainId.BIT_TORRENT_MAINNET]: CurrencyAmount.fromRawAmount(USDT_BITTORRENT, 10_000e6),
  [ChainId.EON]: CurrencyAmount.fromRawAmount(USDC_LZ_EON, 10_000e6),
  [ChainId.BERA_TESTNET]: CurrencyAmount.fromRawAmount(USDC_BERATESTNET, 10_000e6),
  [ChainId.SONIC_TESTNET]: CurrencyAmount.fromRawAmount(USDC_SONICTESTNET, 10_000e18),
  [ChainId.SONIC]: CurrencyAmount.fromRawAmount(USDC_SONIC, 10_000e6),
}

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useStablecoinPrice(currency?: Currency): Price<Currency, Token> | undefined {
  const chainId = currency?.chainId
  const amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined
  const stablecoin = amountOut?.currency
  const isSupported = chainId ? USE_CLIENT_QUOTING.includes(chainId) : false

  const tokenPrice = useUSDAPIQuote(currency?.wrapped)
  const { trade } = useRoutingAPITrade(
    TradeType.EXACT_OUTPUT,
    amountOut,
    currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE,
    !isSupported
  )
  const price = useMemo(() => {
    if (!currency || !stablecoin) {
      return undefined
    }

    // handle usdc
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    if (trade) {
      const { numerator, denominator } = trade.routes[0].midPrice
      return new Price(currency, stablecoin, denominator, numerator)
    }

    if (tokenPrice) {
      const USD_FACTOR = 10 ** stablecoin.decimals
      const scaledNumerator = Math.round(tokenPrice * USD_FACTOR)

      const DENOMINATOR_SCALE_FACTOR = 10 ** currency.decimals

      return new Price(currency, stablecoin, DENOMINATOR_SCALE_FACTOR, scaledNumerator)
    }

    return undefined
  }, [currency, stablecoin, tokenPrice, trade])

  const lastPrice = useRef(price)
  if (
    !price ||
    !lastPrice.current ||
    !price.equalTo(lastPrice.current) ||
    !price.baseCurrency.equals(lastPrice.current.baseCurrency)
  ) {
    lastPrice.current = price
  }
  return lastPrice.current
}

export function useStablecoinValue(currencyAmount: CurrencyAmount<Currency> | undefined | null) {
  const price = useStablecoinPrice(currencyAmount?.currency)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}

/**
 *
 * @param fiatValue string representation of a USD amount
 * @returns CurrencyAmount where currency is stablecoin on active chain
 */
export function useStablecoinAmountFromFiatValue(fiatValue: number | null | undefined) {
  const { chainId } = useWeb3React()
  const stablecoin = chainId ? STABLECOIN_AMOUNT_OUT[chainId]?.currency : undefined

  return useMemo(() => {
    if (fiatValue === null || fiatValue === undefined || !chainId || !stablecoin) {
      return undefined
    }

    // trim for decimal precision when parsing
    const parsedForDecimals = fiatValue.toFixed(stablecoin.decimals).toString()
    try {
      // parse USD string into CurrencyAmount based on stablecoin decimals
      return tryParseCurrencyAmount(parsedForDecimals, stablecoin)
    } catch (error) {
      return undefined
    }
  }, [chainId, fiatValue, stablecoin])
}
