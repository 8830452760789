import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { FarmsIcon } from 'components/Icons/Farms'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Box } from 'nft/components/Box'
import { Column, Row } from 'nft/components/Flex'
import { body, bodySmall } from 'nft/css/common.css'
import { ReactNode, useReducer, useRef } from 'react'
import { Zap } from 'react-feather'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import * as styles from './MenuDropdown.css'
import { NavDropdown } from './NavDropdown'
import { activeMenuFarms, menuItem } from './style.css'

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps['to']
  href?: string
  close?: () => void
  children: ReactNode
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to} className={styles.MenuRow}>
          <Row onClick={close}>{children}</Row>
        </NavLink>
      ) : (
        <Row cursor="pointer" as="a" href={href} target="_blank" rel="noopener noreferrer" className={styles.MenuRow}>
          {children}
        </Row>
      )}
    </>
  )
}

const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`
const PrimaryMenuRowText = ({ children }: { children: ReactNode }) => {
  return <StyledBox className={`${styles.PrimaryText} ${body}`}>{children}</StyledBox>
}
const NewChainBadge = styled.span`
  background: linear-gradient(90deg, #05472a 0%, #013220 100%);
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
`

PrimaryMenuRow.Text = PrimaryMenuRowText

const SecondaryLinkedText = ({
  href,
  onClick,
  children,
}: {
  href?: string
  onClick?: () => void
  children: ReactNode
}) => {
  return (
    <Box
      as={href ? 'a' : 'div'}
      href={href ?? undefined}
      target={href ? '_blank' : undefined}
      rel={href ? 'noopener noreferrer' : undefined}
      className={`${styles.SecondaryText} ${bodySmall}`}
      onClick={onClick}
      cursor="pointer"
    >
      {children}
    </Box>
  )
}

const Separator = () => {
  return <Box className={styles.Separator} />
}

const IconRow = ({ children }: { children: ReactNode }) => {
  return <Row className={styles.IconRow}>{children}</Row>
}

const Icon = ({ href, children }: { href?: string; children: ReactNode }) => {
  return (
    <>
      <Box
        as={href ? 'a' : 'div'}
        href={href ?? undefined}
        target={href ? '_blank' : undefined}
        rel={href ? 'noopener noreferrer' : undefined}
        display="flex"
        flexDirection="column"
        color="neutral1"
        background="none"
        border="none"
        justifyContent="center"
        textAlign="center"
        marginRight="12"
      >
        {children}
      </Box>
    </>
  )
}

const FarmsDropdown = () => {
  const { chainId } = useWeb3React()
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)
  const ref = useRef<HTMLDivElement>(null)
  const hasV3Farms = chainId === ChainId.BIT_TORRENT_MAINNET || chainId === ChainId.FANTOM || ChainId.SONIC
  const { pathname } = useLocation()
  const isActive = pathname.startsWith('/farms')

  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)

  return (
    <>
      <Box position="relative" ref={ref} marginRight="4">
        <Box
          className={isActive ? activeMenuFarms : menuItem}
          display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }}
          width="full"
          color={isOpen ? 'neutral1' : 'white'}
          onClick={toggleOpen}
          cursor="pointer"
        >
          <FarmsIcon height="24px" width="24px" fill="rgb(139, 195, 74)" />
          Farms {hasV3Farms && <NewChainBadge>NEW</NewChainBadge>}
        </Box>
        {isOpen && (
          <NavDropdown top={{ sm: 'unset', lg: '56' }} bottom={{ sm: '50', lg: 'unset' }} right="0">
            <Column gap="16">
              <Column paddingX="8" gap="4">
                {hasV3Farms ? (
                  <>
                    <PrimaryMenuRow to="/farms/v2" close={toggleOpen}>
                      <Icon>
                        <FarmsIcon width={24} height={24} fill="rgb(139, 195, 74)" />
                      </Icon>
                      <PrimaryMenuRow.Text>
                        <Trans>Farms V2</Trans>
                      </PrimaryMenuRow.Text>
                    </PrimaryMenuRow>
                    <PrimaryMenuRow to="/farms/v3" close={toggleOpen}>
                      <Icon>
                        <Zap width={24} height={24} fill="orange" />
                      </Icon>
                      <PrimaryMenuRow.Text>
                        <Trans>
                          Farms V3 <NewChainBadge>NEW</NewChainBadge>{' '}
                        </Trans>
                      </PrimaryMenuRow.Text>
                    </PrimaryMenuRow>
                  </>
                ) : (
                  <>
                    <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }}>
                      <PrimaryMenuRow to="/farms/v2" close={toggleOpen}>
                        <Icon>
                          <FarmsIcon width={24} height={24} fill="rgb(139, 195, 74)" />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Farms V2</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                  </>
                )}
              </Column>
            </Column>
          </NavDropdown>
        )}
      </Box>
    </>
  )
}

export default FarmsDropdown
